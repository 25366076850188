<template>
    <div class="item mb-2">
        <span>{{ title }}:</span>
        <div class="d-flex justify-content-between align-items-center">
            <div class="fs-12 border p-2 flex-shrink-0 min-h-33" style="width: 65%">{{ item.comment }}</div>
            <div class="align-items-center d-flex flex-wrap justify-content-center position-relative"
                 style="min-width: 50px; max-width: 100px; font-size: 12px">
                <table class="custom-table">
                    <tr>
                        <td>
                            {{ item.top_left }}
                        </td>
                        <td>
                            {{ item.top_right }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ item.bottom_left }}
                        </td>
                        <td>
                            {{ item.bottom_right }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "PreviewLeftItem",
    props: {
        title: {type: String},
        item : {
            type: Object,
        }
    }
}
</script>

<style scoped>
.custom-table tr {
    border-bottom: 1px solid #000;
    height: 10px;
}

.custom-table tr:last-child {
    border-bottom: 0;
}

.custom-table tr td {
    border-right: 1px solid #000;
    text-align: center;
    width: 10px;
}

.custom-table tr td:last-child {
    border-right: 0;
}
</style>
